'use strict';

var app = {
	initd: false,
	elements: {},

	init: function(){
		if (this.initd) {
			return;
		}
		this.initd = true;

		this.elements.sidenav = $('#side-nav').addClass('is-contracted');
		this.elements.sidenavToggle = $('#sidenav-toggle');

		this.toggleFeatures();
		this.svgFallback();
	},

	svgFallback: function(){
		if(!Modernizr.svg){
			var svgs = document.getElementsByTagName('img');
			var svg;

			for (var i =0; i < svgs.length; ++i) {
				svg = svgs[i];
				if(svg.src.split('.').pop() === 'svg'){
					svg.src = svg.src.substr(0, svg.src.lastIndexOf('.')) + '.png';
				}
		    }

		}
	},

	toggleFeatures: function(){
		var that = this;
		that.elements.sidenavToggle.on('click', function(){
			that.elements.sidenav.toggleClass('is-contracted');
		});
	}
};

app.init();

function redirect(page)
{
var s;
var s1;
var s2;
s = 'support';
s1 = 'mailto:';
s2 = '@querytool.com';

if (page === 1) {
	s = 'aqt';
}

if (page === 3) {
	s2 = '@cardett.co.nz';
}

window.location.href = s1.concat(s).concat(s2);
}
